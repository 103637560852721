<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Envio de SMS</h1>
      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            v-model="itensSelect"
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            :single-select="singleSelect"
            show-select
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage">
            <template v-slot:top>
              <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
            </template>
              <template v-slot:top>
                <!-- Select tipo de entidade -->
                <BrToolBar @search="buscar" :configFilter="{ listaSelect: listKeys, getItens: getItensEntities, jsonData: true }" :select="listaTipoBusca" :labelTextField="'Busca por CPF'">
                <v-menu
                  transition="slide-y-transition"
                  bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="btn-filtro"
                      v-on="on"
                      tile
                      outlined
                    >
                      Instituição
                    </v-btn>
                  </template>
                </v-menu>
                
                </BrToolBar>
              </template>
             

              <template v-slot:item.phone="{ item }">
                {{ item.phone | phone }}
              </template>

              <template v-slot:item.document="{ item }">
                {{ item.document | document }}
              </template>

              <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="permiteAcao($route, 'edit')"
                  small
                  @click="editItem(item)"
                  tile
                  outlined 
                  :color="variables.colorPrimary"
                  class="mr-2"
                >
                  Editar
                </v-btn>

                <v-btn
                  v-if="permiteAcao($route, 'delete')"
                  small
                  @click="deleteItem(item)"
                  tile
                  outlined 
                  :color="variables.colorError"
                >
                  Excluir
                </v-btn>
              </template>

              <template v-slot:footer>
                <div class="wrapper-footer">
                  <div class="qtd-paginacao">
                    {{ pageStart }}
                    -
                    {{ pageStop }}
                    de
                    {{ totalItens }}
                  </div>
                  <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                    circle
                    :disabled="disabled"
                    :total-visible="7"
                    class="box-table-paginacao"
                    v-if="numberOfPages > 1"></v-pagination>
                  <v-btn color="primary" @click="modalOpen()" class="br-btn"> Enviar sms</v-btn>
                </div>
              </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import listagemTables from '@/views/mixins/listagemTables'
import { map, size } from 'lodash'
import { errorSnackbar } from '@/core/service/utils'

export default {
  name: 'EnvioSMS',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    singleSelect: false,
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
      { align: 'start', class: 'table-header', text: 'Telefone', value: 'phone' },
      { align: 'start', class: 'table-header', text: 'CPF/CNPJ', value: 'document' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    tipoBusca: 6,
    listaTipoBusca: [
      {text: 'ATS', value: 1 },
      {text: 'OSC ', value: 2 },
      {text: 'CREDENCIADOR ', value: 3 },
      {text: 'ESTABELECIMENTO ', value: 4 },
      {text: 'CAIXA ', value: 5 },
      {text: 'USUARIO WEB ', value: 6 },
      {text: 'INFLUENCIADOR', value: 7 }
    ],
    typeBusca: 'entities',
    itensSelect: []
  }),
  computed: {
    ...mapGetters('SendSms', ['listaItens', 'totalItemSelect']),
    listKeys () {
      return { name: '', telefone: '', cpf: ''}
    },
    totalItens () {
      return this.totalItemSelect || 0
    }
  },
  created () {
    this.filtroEntities()
  },
  mounted () {
    const vm = this
    Events.$on('sms:clearTable', () => {
      console.log('chegou aqui')
      vm.itensSelect = [] })
  },
  watch: {
    tipoBusca (v) {
      this.addFiltroAtual(v).then(() => this.filtroEntities(v) )
    },
    itensSelect (val) {
      this.addDadosTable(this.envioSms(val))
    }
  },
  methods: {
    ...mapActions('SendSms', { getItens: 'getItens', getItensEntities: 'obterItens' }),
    ...mapActions('SendSms', ['addFiltroAtual', 'addDadosTable']),

    modalOpen () { Events.$emit('Daodos::modal::sms') },

    filtroEntities (v = false) {
      const vm = this
      this.getItensEntities({
        pageSize: this.$store.getters.ItensPaginacao,
        _filter: { "entityTypeId_In":`${ !v ? this.tipoBusca : v}` }
      }).then(() => {
        vm.loadingTable = false
      })
    },
    envioSms () {
      let {itensSelect} = this
      return size(itensSelect) !== 0 ? map(itensSelect, v => ({numero: v.phone}) ) : []
    },

    buscar(val) {
      this.loadingPag = true
      const document = val.replace(/\D/g, '')
      this.objCampoBusca['_addlFilter'] = { "Entity:jsonData->>'cpf'_ilike":`%${document}%` }
      this
        .getItensEntities({ _addlFilter: { "Entities:jsonData->>'cpf'_ilike":`%${document}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },
  }
}
</script>

<style lang="scss">
  @import '../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
  // .filtro-entities{
  //   position: absolute;
  //   z-index: 1;
  //   top: -16.5px;
  //   @media(max-width: 600px){
  //     position: relative;
  //     width: 100% !important;
  //   }
  // }
</style>>
